<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('fbuy.title')"
        left-arrow
        @click-left="$router.go('-1')"
      />
    </div>
    <div class="maincontent">
      <!-- <van-row class="top">
        <van-row class="htext">
          <span v-if="info.status === '-1'">交易取消</span>
          <span v-if="info.status === '0'">待付款</span>
          <span v-if="info.status === '1'">已付款</span>
          <span v-if="info.status === '2'">已放币</span>
        </van-row>
      </van-row> -->
      <van-row class="info">
        <van-cell :title="$t('fbuy.total')">
          <template #default>
            <span>{{ info.price * info.trade_num }}</span>
          </template>
        </van-cell>
        <van-cell :title="$t('fbuy.price')">
          <template #default>
            <span>{{ info.price }}</span>
          </template>
        </van-cell>
        <van-cell :title="$t('fbuy.number')">
          <template #default>
            <span>{{ info.trade_num }}</span>
          </template>
        </van-cell>

        <van-cell :title="$t('fbuy.orderno')">
          <template #default>
            <span>{{ info.code }}</span>
          </template>
        </van-cell>
        <van-cell :title="$t('fsell.nick')">
          <template #default>
            <span>{{ info.nickname }}</span>
          </template>
        </van-cell>
        <van-cell :title="$t('fbuy.remark')">
          <template #default>
            <span>{{ info.remark }}</span>
          </template>
        </van-cell>
      </van-row>
      <van-button
        v-if="info.status === '1'"
        class="submitBtn"
        @click="confirm"
        >{{ $t('fsell.finish') }}</van-button
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      info: {}
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    async getinfo() {
      const { data } = await this.$http.get(
        '/home/trade/sell_detail/id/' + this.$route.params.id
      )
      if (data) {
        if (data.code === 200) {
          this.info = data.data
          this.time = data.data.times * 1000
        }
      }
    },
    // 确认收款
    async confirm() {
      const { data } = await this.$http.post('/home/trade/confirm', {
        id: this.info.id
      })
      if (data) {
        if (data.code === 200) {
          this.$toast.success(this.$t('common.success'))
          this.getinfo()
        } else {
          this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')))
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  /deep/.van-nav-bar__title {
    font-size: 1rem;
    color: #2c3e50;
  }
  /deep/.van-nav-bar__text {
    font-size: 0.98667rem;
    color: #2c3e50;
  }
}
.maincontent {
  padding: 56px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #f7f7f7;
  min-height: 100vh;
  .top {
    background: #fff;
    padding: 20px;
    text-align: left;
    .htext {
      font-size: 1.8rem;
    }
    .time {
      color: #d90012;
      line-height: 2rem;
      margin-top: 5px;
    }
    .van-count-down {
      display: inline-block;
      color: #d90012;
      font-size: 1.4rem;
    }
  }
  .info {
    margin-top: 8px;
    .van-cell {
      line-height: 30px;
    }
    .van-cell__title {
      text-align: left;
    }
    .van-cell__value {
      color: #888;
    }
  }
  .submitBtn {
    margin: 10% auto 0 auto;
    width: 80%;
    letter-spacing: 0.53333rem;
    height: 3rem;
    font-size: 0.93333rem;
    color: #fff;
    background-color: #004ea3;
    border-radius: 0.53333rem;
    border: none;
    box-shadow: 0 0 1.33333rem #004ea3;
  }
}
</style>
